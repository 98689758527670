
import { defineComponent, PropType } from "vue";
import { mapGetters } from "vuex";

type Level = {
  id: number;
  name: string;
  weight: number;
  updated_at: string;
  created_at: string;
  deleted_at: string | null;
};

export default defineComponent({
  name: "LevelsDataTable",
  props: {
    tableData: Array as PropType<Level[]>,
    default: []
  },
  data: () => ({
    model: {}
  }),
  methods: {
    emitEditEvent(level) {
      this.$emit("editModalOpened", level);
    },
    openDeleteModal(level) {
      this.$emit("deleteModalOpened", level);
    },
    buildModel() {
      if (this.tableData && this.tableData.length > 0) {
        for (let i = 0; i < this.tableData.length; i++) {
          this.model[this.tableData[i].id] = {
            weight: this.tableData[i].weight,
            id: this.tableData[i].id
          };
        }
      }
    },
    emitWeights() {
      this.$emit("saveWeights", this.model);
    }
  },
  computed: {
    ...mapGetters("LevelsModule", ["isSavingWeights"]),
    hasModel() {
      return Object.keys(this.model).length > 0;
    }
  },
  mounted() {
    this.buildModel();
  }
});
