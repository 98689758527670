
import { ComponentPublicInstance, defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";
import LevelModal from "@/components/settings/modals/LevelModal.vue";
import DeleteModal from "@/components/general/DeleteModal.vue";
import LevelsDataTable from "@/components/settings/Tables/LevelsDataTable.vue";
import LoadingSpinner from "@/components/general/LoadingSpinner.vue";
import Swal from "sweetalert2";

export default defineComponent({
  name: "JobsOverview",
  components: {
    LevelsDataTable,
    LevelModal,
    DeleteModal,
    LoadingSpinner
  },
  data() {
    return {
      currentlySelectedLevel: {
        id: null,
        name: null
      },
      isThereAModalActive: false,
      modalSetup: {
        action: "",
        title: ""
      }
    };
  },
  props: {
    widgetClasses: String
  },
  methods: {
    ...mapActions("LevelsModule", [
      "fetchData",
      "createNew",
      "update",
      "destroyData",
      "saveWeights"
    ]),
    openAddNewModal() {
      this.resetLevelData();
      this.modalSetup.title = "";
      this.$nextTick(() => (this.modalSetup.title = "Add new Level"));
      this.modalSetup.action = "addnew";
      this.isThereAModalActive = true;
    },
    handleSaveWeight(data) {
      this.saveWeights(data)
        .then(message => {
          this.swalAlert(message, false);
        })
        .catch(err => {
          this.swalAlert(err, true);
        });
    },
    swalAlert(message, hasError) {
      console.log(message);
      Swal.fire({
        position: "top-end",
        toast: true,
        icon: hasError ? "error" : "success",
        title: message,
        showConfirmButton: false,
        timer: 1200
      });
    },
    saveDataFromModal(dataFromModal) {
      if (dataFromModal.action == "edit") {
        this.update({
          values: dataFromModal.payload,
          id: this.currentlySelectedLevel.id
        });
      } else {
        this.createNew(dataFromModal.payload);
      }
    },
    openEditModal(level) {
      this.modalSetup.title = "Edit Level";
      this.modalSetup.action = "edit";
      this.currentlySelectedLevel = level;
    },
    cancelModal() {
      this.isThereAModalActive = false;
    },
    openDeleteModal(entry) {
      this.currentlySelectedLevel = entry;
      this.isThereAModalActive = true;
    },
    deleteSelectedLevel() {
      this.destroyData(this.currentlySelectedLevel.id);
      this.isThereAModalActive = false;
      this.currentlySelectedLevel.id = null;
    },
    resetLevelData() {
      this.currentlySelectedLevel = {
        id: null,
        name: null
      };
    }
  },
  computed: {
    ...mapGetters("LevelsModule", ["getData", "loading", "isSavingWeights"])
  },
  mounted() {
    this.fetchData();
  }
});
